import { EventList, EventListContent } from '@/components/event-list';
// import { ProgramViewGrid } from '@/js/shared/components/info/ProgramViewGrid';
import { ConferenceContext } from '@/js/shared/context/ConferenceContext';
import { findCurrentDayId } from '@/js/shared/utils/DataFormat';
import { getSpacerValue } from '@/js/shared/utils/format';
import { IEvent } from '@/types/event';
import { INode, INodeProps } from '@/types/node';
import { IProgramRoom } from '@/types/room';
import {
  Box,
  Container,
  Grid,
  Hidden,
  Icon,
  IconButton,
  makeStyles,
  Tab,
  Tabs,
  Theme,
  Tooltip,
} from '@material-ui/core';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { IDay } from '@/types/day';
import { IContent } from './contract';
import { useNodeProps } from '@/node-editor/hooks';
import { RoomContext } from '@/js/shared/context/RoomContext';
import { favoriteEvents } from '../../../shared/utils/dataReducers';

interface INodeExtended extends Omit<INode, 'content'> {
  content: IContent;
}

interface IProps extends Omit<INodeProps, 'node'> {
  node: INodeExtended;
}

const useStyles = makeStyles<
  Theme,
  Omit<IContent, 'defaultDisplay'> & { override: Record<string, any> }
>((theme) => ({
  root: ({ alignment, verticalSpacing, override }) => ({
    // display: 'flex',
    // justifyContent: alignment.horizontal,
    // alignItems: alignment.vertical,
    // paddingTop: theme.spacing(getSpacerValue(verticalSpacing.top)),
    // paddingBottom: theme.spacing(getSpacerValue(verticalSpacing.bottom)),
    overflow: 'hidden',
    ...override.root,
  }),
  gridContainer: {
    justifyContent: 'space-between',
    marginBottom: theme.spacing(1),
  },
  forumLink: {
    color: theme.palette.grey[500],
  },
}));

export const Render = ({
  node: {
    id,
    override,
    content: { defaultDisplay },
  },
}: IProps) => {
  const classes = useStyles({ override });
  const {
    conferenceState: {
      conferenceDetail,
      conferenceDetail: { programDays: days },
    },
  } = useContext(ConferenceContext);

  const { roomState } = useContext(RoomContext);

  const findCurrentRoom = (conferenceData, roomData) => {
    const activeCurrentDay = conferenceData.programDays.find(
      (day) => day.id === roomData.programDay.id
    );
    let currentProgramRoom = activeCurrentDay.programRooms.find(
      (programroom) => programroom.id === roomData.id
    );
    return currentProgramRoom;
  };

  const [currentProgramRoom, setCurrentProgramRoom] = useState(
    findCurrentRoom(conferenceDetail, roomState.room)
  );

  const [currentTab, setCurrentTab] = useState(
    findCurrentDayId(conferenceDetail)
  );

  const currentDay = useMemo((): IDay => {
    return days.find((d: IDay) => d.id === currentTab);
  }, [currentTab]);
  const totalFavorites = favoriteEvents(conferenceDetail, currentTab);

  useEffect(() => {
    setCurrentProgramRoom(findCurrentRoom(conferenceDetail, roomState.room));
  }, [totalFavorites.length]);

  if (!conferenceDetail || days.length === 0) {
    console.log('No active days, so dont render the program');
    return null; // Don't render with 0 active days
  }

  // Display program as list or grid?
  const defaultDisplayType = () =>
    defaultDisplay === 'grid' ||
    (defaultDisplay === 'auto' && currentDay.programRooms.length > 1);
  const [displayGrid, setDisplayGrid] = useState(defaultDisplayType());

  useEffect(() => {
    setDisplayGrid(defaultDisplayType());
  }, [currentDay.id]);

  const eventStepper = currentDay.programRooms.reduce(
    (acc, { id, currentEventId, video_state }) => ({
      ...acc,
      current: [...acc.current, currentEventId].filter(Boolean),
      finished: [...acc.finished, video_state === 'file' ? id : null].filter(
        Boolean
      ),
    }),
    { current: [], finished: [] }
  );

  const programReducer = (rooms: IProgramRoom[]): IEvent[][] => {
    const eventMap = new Map();

    rooms
      .reduce((acc, curr) => [...acc, ...curr.events], [])
      .forEach((event) => {
        const prev = eventMap.get(event.start_time);

        return !prev
          ? eventMap.set(event.start_time, [event])
          : prev.push(event);
      });

    const sorted = new Map([...eventMap.entries()].sort());

    return [...sorted.values()];
  };

  // const autoGrid = () =>
  //   defaultDisplay === 'auto' && currentDay.programRooms.length > 1;

  return (
    <Container
      maxWidth={false}
      classes={{ root: classes.root }}
      {...useNodeProps(id)}
    >
      <Box maxWidth={800} style={{ width: '100%' }}>
        {currentProgramRoom && (
          <EventList
            rooms={[currentProgramRoom]}
            list={programReducer([currentProgramRoom])}
            stepper={eventStepper}
          >
            {({ event, ...provided }) => (
              <EventListContent
                event={event}
                nrOfRooms={currentDay.programRooms.length}
                hideProgramSubHeader={true}
                hideFavoriteButton={true}
                {...provided}
              >
                {/* {provided.eventStatus === 'passed' &&
                  !window.SETTINGS.NEW_DISCUSSION_MODERATE && (
                    <Link
                      className={classes.forumLink}
                      to={`/live/session/${event.id}`}
                    >
                      Forum
                    </Link>
                  )} */}
              </EventListContent>
            )}
          </EventList>
        )}
      </Box>
    </Container>
  );
};
