import { EventList, EventListContent } from '@/components/event-list';
import { ProgramViewGrid } from '@/js/shared/components/info/ProgramViewGrid';
import { ConferenceContext } from '@/js/shared/context/ConferenceContext';
import { findCurrentDayId } from '@/js/shared/utils/DataFormat';
import { getSpacerValue } from '@/js/shared/utils/format';
import { IEvent } from '@/types/event';
import { INode, INodeProps } from '@/types/node';
import { IProgramRoom } from '@/types/room';
import {
  Box,
  Button,
  Container,
  Grid,
  Icon,
  IconButton,
  makeStyles,
  Tab,
  Tabs,
  Theme,
  Tooltip,
} from '@material-ui/core';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { IDay } from '@/types/day';
import { IContent } from './contract';
import { useNodeProps } from '@/node-editor/hooks';
import { MarkdownRender } from '@/js/shared/components/markdown/MarkdownRender';
import { favoriteEvents } from '../../../shared/utils/dataReducers';

interface INodeExtended extends Omit<INode, 'content'> {
  content: IContent;
}

interface IProps extends Omit<INodeProps, 'node'> {
  node: INodeExtended;
}

const useStyles = makeStyles<
  Theme,
  Omit<IContent, 'defaultDisplay'> & { override: Record<string, any> }
>((theme) => ({
  root: ({ alignment, verticalSpacing, override }) => ({
    display: 'flex',
    justifyContent: alignment.horizontal,
    alignItems: alignment.vertical,
    paddingTop: theme.spacing(getSpacerValue(verticalSpacing.top)),
    paddingBottom: theme.spacing(getSpacerValue(verticalSpacing.bottom)),
    overflow: 'hidden',
    ...override.root,
  }),
  gridContainer: {
    flexWrap: 'nowrap',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(0.5),
  },
  menuContainer: {
    width: 'calc(100% - 50px)',
  },
  forumLink: {
    color: theme.palette.grey[500],
  },
  addToCal: {
    paddingLeft: 49,
    paddingTop: 8,
    marginBottom: -8,
  },
}));

export const Render = ({
  node: {
    id,
    override,
    content: { alignment, defaultDisplay, verticalSpacing },
  },
}: IProps) => {
  const classes = useStyles({ alignment, verticalSpacing, override });

  const {
    conferenceUpdateStepper,
    conferenceState: {
      conferenceDetail,
      conferenceDetail: { programDays: days },
    },
  } = useContext(ConferenceContext);

  if (!conferenceDetail || days.length === 0) {
    console.log('No active days, so dont render the program');
    return null; // Don't render with 0 active days
  }

  const [currentTab, setCurrentTab] = useState(
    findCurrentDayId(conferenceDetail)
  );
  useEffect(() => {
    setCurrentTab(findCurrentDayId(conferenceDetail));
  }, [conferenceUpdateStepper]);

  const totalFavorites = favoriteEvents(conferenceDetail, currentTab);

  const currentDay = useMemo((): IDay => {
    return days.find((d: IDay) => d.id === currentTab);
  }, [currentTab, conferenceUpdateStepper, totalFavorites.length]);
  // Display program as list or grid?
  const defaultDisplayType = () =>
    defaultDisplay === 'grid' ||
    (defaultDisplay === 'auto' && currentDay.programRooms.length > 1);
  const [displayGrid, setDisplayGrid] = useState(defaultDisplayType());

  useEffect(() => {
    setDisplayGrid(defaultDisplayType());
  }, [currentDay.id, conferenceUpdateStepper]);

  const eventStepper = currentDay.programRooms.reduce(
    (acc, { id, currentEventId, video_state }) => ({
      ...acc,
      current: [...acc.current, currentEventId].filter(Boolean),
      finished: [...acc.finished, video_state === 'file' ? id : null].filter(
        Boolean
      ),
    }),
    { current: [], finished: [] }
  );

  const programReducer = (rooms: IProgramRoom[]): IEvent[][] => {
    const eventMap = new Map();

    rooms
      .reduce((acc, curr) => [...acc, ...curr.events], [])
      .forEach((event) => {
        const prev = eventMap.get(event.start_time);

        return !prev
          ? eventMap.set(event.start_time, [event])
          : prev.push(event);
      });

    const sorted = new Map([...eventMap.entries()].sort());

    return [...sorted.values()];
  };

  // const autoGrid = () =>
  //   defaultDisplay === 'auto' && currentDay.programRooms.length > 1;

  return (
    <Container
      maxWidth={false}
      classes={{ root: classes.root }}
      {...useNodeProps(id)}
    >
      <Box maxWidth={800} style={{ width: '100%' }}>
        <Grid container className={classes.gridContainer}>
          <Grid item className={classes.menuContainer}>
            <Tabs
              value={currentTab}
              onChange={(_e, current: string) => setCurrentTab(current)}
              variant="scrollable"
              textColor="primary"
              indicatorColor="primary"
              aria-label="scrollable auto tabs example"
            >
              {days &&
                days.length > 0 &&
                days.map((day: IDay) => (
                  <Tab key={day.id} value={day.id} label={day.title} />
                ))}
            </Tabs>
            {totalFavorites.length > 0 && (
              <Box className={classes.addToCal}>
                <Button
                  size="small"
                  color="primary"
                  startIcon={<Icon>event</Icon>}
                  target="_blank"
                  href={`/api/visitor/conferences/${conferenceDetail.id}/downloadfavorites`}
                >
                  {window.LANG.ADD_TO_CAL} ({totalFavorites.length})
                </Button>
              </Box>
            )}
          </Grid>
          <Grid item>
            <Tooltip title={displayGrid ? 'View as list' : 'View as calendar'}>
              <IconButton
                onClick={() => setDisplayGrid(!displayGrid)}
                color="primary"
                component="span"
              >
                <Icon>{displayGrid ? 'calendar_view_day' : 'event'}</Icon>
              </IconButton>
            </Tooltip>
          </Grid>
        </Grid>

        {currentDay && (
          <>
            {currentDay.description && (
              <Box>
                <MarkdownRender text={currentDay.description} />
              </Box>
            )}

            {displayGrid ? (
              <ProgramViewGrid
                // @ts-expect-error: React.memo type error
                programDay={currentDay}
                programDayId={currentDay.id}
                totalFavorites={totalFavorites}
                updateStepper={conferenceUpdateStepper}
              />
            ) : (
              <EventList
                rooms={currentDay.programRooms}
                list={programReducer(currentDay.programRooms)}
                stepper={eventStepper}
              >
                {({ event, ...provided }) => (
                  <EventListContent
                    event={event}
                    nrOfRooms={currentDay.programRooms.length}
                    {...provided}
                  >
                    {/* {provided.eventStatus === 'passed' &&
                      !window.SETTINGS.NEW_DISCUSSION_MODERATE && (
                        <Link
                          className={classes.forumLink}
                          to={`/live/session/${event.id}`}
                        >
                          Forum
                        </Link>
                      )} */}
                  </EventListContent>
                )}
              </EventList>
            )}
          </>
        )}
      </Box>
    </Container>
  );
};
